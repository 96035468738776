<template>
  <v-container fluid>
     <SideBar />
    <router-view />
  </v-container>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';

export default {
  name: 'Editor',
  components: {
    SideBar: () => import('@/components/sideBar'),
  },
  mixins: [colorMxn],
  data() {
    return {
      editor: null,
      btnColor: '#71B7F9',
      loading: false,
    }
  },
 
  methods: {
    submitCode() {
      this.loading = true;
      setTimeout(()=> {
        this.loading= false;
      }, 3000);
    }
  },
}
</script>

<style>
#editor {
  min-width: 500px; 
  min-height: 80vh;
}
</style>
